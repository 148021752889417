<template>
  <div>
    <el-card>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/notice' }"
          >通知公告</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加文章</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="el-form"
        @submit.native.prevent
      >
        <el-form-item label="文章标题">
          <el-input v-model="form.title" placement="请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
          <editor ref="editor" />
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="6">
              <!-- accept=".doc,.docx,.xls,.ppt,.xlsx,.pptx,.zip" -->
              <el-upload
                class="upload-demo"
                :action="host + '/file'"
                :before-upload="beforeAvatarUpload"
                :on-preview="handlePreview"
                :on-success="handleChange"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="20"
                :file-list="fileList"
              >
                <el-button size="small" type="primary" class="text"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  可上传Word/Excel/Pdf或压缩包文件(10mb内)
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col>
          <el-button type="info" @click="requestCancel" class="text mr-4"
            >取消</el-button
          >
          <el-button
            type="primary"
            :loading="loading"
            class="text"
            @click="requestCreate"
            >确定</el-button
          >
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import config from "@/utils/config";
import editor from "../../../components/editor";

export default {
  data() {
    return {
      loading: false,
      host: config.url,
      form: {
        columnId: "",
        title: "",
        content: "",
        fileIds: [],
      },
      fileList: [],
      fileIds: [],
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      const isDocx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const isDoc = file.type === "application/msword";
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isZip = file.type === "application/zip";
      const isRar = file.type === "application/x-rar";
      const isPdf = file.type === "application/pdf";
      const isZ = file.type === "application/x-7z-compressed";
      if (!isLt2M) {
        this.$message.error("可上传Word/Excel/Pdf或压缩包文件(10mb内)");
        return false;
      }
      if (
        isDocx ||
        isDoc ||
        isXlsx ||
        isXls ||
        isZip ||
        isRar ||
        isPdf ||
        isZ
      ) {
      } else {
        this.$message.error("可上传Word/Excel/Pdf或压缩包文件(10mb内)");
        return false;
      }
    },
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    getData() {
      let self = this;
      this.$axios
        .get("/column/page")
        .then((response) => {
          if (response.data.status === 200) {
            response.data.data.records.forEach((Array) => {
              if (Array.name === "通知公告") {
                self.form.columnId = Array.id;
              }
            });
          } else {
            self.$root.warn("数据加载失败" + response.data.status);
          }
          self.total = response.data.data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    stopbut() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    requestCreate() {
      this.form.content = this.$refs.editor.myValue;
      let self = this;
      if (self.form.title.length <= 0) {
        this.loading = true;
        self.stopbut();
        self.$root.warn("请输入文章标题", "");

        return;
      }
      if (self.form.content.length <= 0) {
        self.$root.warn("请输入文章内容", "");
        this.loading = true;
        self.stopbut();
        return;
      }
      self.$axios
        .post("/article", self.form)
        .then((response) => {
          if (response.data.status === 200) {
            self.$root.success("添加成功");
            self.stopbut();
            self.form = {};
            self.$router.push({ name: "notice" });
          } else {
            self.$root.warn("添加失败");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 文件上传成功后
    handleChange(response) {
      console.log(response);
      if (response.status === 200) {
        this.form.fileIds.push(response.data.id);
        console.log(this.form.fileIds);
      }
    },
    handleRemove(file, fileList) {
      let id = file.id || file.response.data.id;
      if (id) {
        this.form.fileIds.forEach((item, i) => {
          if (item === id) this.form.fileIds.splice(i, 1);
        });
      }
    },
    handlePreview(file) {},
    beforeRemove(file, fileList) {},
    requestCancel() {
      this.$router.back();
    },
  },
  components: {
    editor,
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.el-form {
  margin: 32px 32px 0;
}

.text {
  color: #ffffff;
}
</style>
